import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ItemData, MapDrawingDto, MapRecordedRouteDto, MultiStopRouteDto, UserLogged} from 'src/app/types/dtos/models';
import {Clipboard} from '@angular/cdk/clipboard';
import {ToastService} from 'src/app/services/toast.service';
import {Menu, MenuItem} from 'primeng';
import {UtilitiesService} from 'src/app/services/utilities.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';
import {FEATURES, ItemType} from 'src/app/types/enums';
import {environment} from 'src/environments/environment';

@Component({
	selector: 'app-route-card',
	templateUrl: './route-card.component.html',
	styleUrls: ['./route-card.component.scss'],
})
export class RouteCardComponent implements OnInit, OnDestroy {
	@Input() route: MultiStopRouteDto | MapDrawingDto | MapRecordedRouteDto;
	@Input() type: 'route' | 'drawing' | 'recorded-route';
	@Input() showClone: boolean = true;
	@Input() displayUrl: boolean;
	public recordRouteLink = '';
	recordedRouteLastStop: Partial<ItemData> = null;
	@Output() clone = new EventEmitter<MultiStopRouteDto | MapDrawingDto | MapRecordedRouteDto>();
	@Output() delete = new EventEmitter<MultiStopRouteDto | MapDrawingDto | MapRecordedRouteDto>();
	@Output() edit = new EventEmitter<MultiStopRouteDto | MapDrawingDto | MapRecordedRouteDto>();
	@Output() quickView = new EventEmitter<MultiStopRouteDto | MapDrawingDto | MapRecordedRouteDto>();
	@Output() share = new EventEmitter<MultiStopRouteDto | MapDrawingDto | MapRecordedRouteDto>();
	private destroy$: Subject<void> = new Subject<void>();

	public displayRouteReturn: boolean = false;
	public returnRouteMenu: Menu = undefined;
	public returnRouteItems: MenuItem[] = [
		{
			label: 'Quick view',
			command: (event) => {
				this.routeReturnQuickView();
			},
		},
		{
			label: 'Edit',
			command: (event) => this.routeReturnEdit(),
		},
		{
			label: 'Delete',
			command: (event) => this.routeReturnDelete(),
		},
	];
	public user: UserLogged;

	constructor(
		private clipboard: Clipboard,
		private toastService: ToastService,
		private utilitiesService: UtilitiesService,
		private authService: AuthService
	) {
		this.user = this.authService.currentUserValue;
	}

	ngOnInit(): void {
		if (this.isRecordedRouteDto(this.route)) {
			this.createRecordRouteLink(this.route);
		}
		this.displayRouteReturn = this.authService.userHasFeature(FEATURES.CREATE_ROUTE_RETURN);
		this.utilitiesService.menu$.pipe(takeUntil(this.destroy$)).subscribe((menu) => {
			if (menu) {
				this.returnRouteMenu = menu;
			}
		});
		if (this.isRecordedRouteDto(this.route)) {
			const stops = this.route.items.filter((item) => item.type === ItemType.STOP);
			stops ? (this.recordedRouteLastStop = stops[stops.length - 1]) : null;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public isMultiStopRouteDto = (
		route: MultiStopRouteDto | MapDrawingDto | MapRecordedRouteDto
	): route is MultiStopRouteDto => {
		return this.type === 'route';
	};

	public isRecordedRouteDto = (
		route: MultiStopRouteDto | MapDrawingDto | MapRecordedRouteDto
	): route is MapRecordedRouteDto => {
		return this.type === 'recorded-route';
	};

	public isReviewed = (route: MapRecordedRouteDto): boolean => {
		return route.reviewedBy.some((userId) => userId === this.user?.id);
	};

	handleClick = (url: string) => {
		this.clipboard.copy(url);
		this.toastService.show({severity: 'success', summary: 'Copied to clipboard'});
	};

	handleShareContent = (event: any) => {
		this.share.emit(event);
	};

	toggleRouteReturnMenu = (event: any) => {
		this.returnRouteMenu.model = this.returnRouteItems;
		this.returnRouteMenu.toggle(event);
	};

	routeReturnQuickView = () => {
		const route = this.route as MultiStopRouteDto;
		this.quickView.emit(route.return);
	};

	routeReturnEdit = () => {
		const route = this.route as MultiStopRouteDto;
		this.edit.emit(route.return);
	};

	routeReturnDelete = () => {
		const route = this.route as MultiStopRouteDto;
		this.delete.emit(route.return);
	};

	createRecordRouteLink = (route: MapRecordedRouteDto) => {
		const name = route.name;
		if (name) {
			const regex = /[/=?#& ]/g;
			const newName = name.replace(regex, '-');
			const RECORDED_ROUTE_NEW_ID_OFFSET = 300_000;
			this.recordRouteLink = `${environment.apiHostv3}/branchio/${newName}-id=${
				route.id
			}/eventType=shareRecordRoutes/eventId=${route.id}/routeId=${+route.id + RECORDED_ROUTE_NEW_ID_OFFSET}`;
		}
	};
}
