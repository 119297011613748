import {FeatureCollectionGeoJson} from 'src/app/helpers/shareHelper';
import {StopType} from '../enums';
import {GeoNoteDto, ItemData, UserBasicInfoDto, VehicleProfileDto} from './models';

export const MESSAGE_LOAD_AMOUNT = 10;

export enum MESSAGE_TYPES {
	TEXT = 'text',
	MARKER = 'marker',
	CUSTOM_MARKER = 'custom_marker',
	FAVORITE_MARKER = 'favorite_marker',
	FAVORITE_FOLDER = 'favorite_folder',
	IMAGE = 'image',
	ROUTE = 'route',
	CUSTOM_ROUTE = 'custom_route',
	CUSTOM_FOLDER = 'custom_folder',
	RECORDED_ROUTE = 'recorded_route',
}

export interface ChatUser {
	id: string;
	name?: string;
}

export interface ChatThread {
	creator_id: string;
	default_user_role: number;
	read_receipts: ReadReceipt;
	name?: string;
	member_ids: string[];
	user_roles: UserRole[];
}

export interface ReadReceipt {
	[user_id: string]: string;
}

export interface UserTimestamp {
	timestamps: {
		[threadId: string]: {
			read_receipt: Timestamp;
			timestamp: Timestamp;
		};
	};
}

export interface Timestamp {
	seconds: number;
	nanoseconds: number;
}

export interface MessageTimestamp {
	[uid: string]: number;
}

export interface UserRole {
	[uid: string]: number;
}

export interface Message<T> {
	id: string;
	message_type: string;
	author?: ChatUser;
	timestamp?: any;
	content: T;
}

export type ChatGate = {
	name: string;
	lat: number;
	lng: number;
	latitude: number;
	longitude: number;
	markerId: number;
};

export type ChatMarker = {
	id: number;
	name: string;
	layerId: string;
	gates?: ChatGate[];
	text?: string;
};

export type ChatFavoriteMarker = ChatMarker & {
	color: string;
};

export type ChatCustomMarker = {
	id: number;
	name: string;
	lat: number;
	lng: number;
	color?: string;
	text?: string;
};

export type ChatRoute = {
	id?: number;
	routeId?: number;
	name: string;
	description?: string;
	layerId: string;
	draws: string[]; //The draws are stored as a stringified array of DrawToShareDto
	stops: ChatRouteStop[];
	isHazmat: boolean;
	vehicleProfileId: number;
	vehicleProfile?: VehicleProfileDto;
	items: GeoNoteDto[];
};

export type ChatRecordedRoute = {
	id?: number;
	name: string;
	description?: string;
	geoJson: FeatureCollectionGeoJson;
	items: Partial<ItemData>[];
	createdByUser: UserBasicInfoDto;
};

export type ChatRouteStop = {
	id?: number;
	name: string;
	type: StopType;
	lat: number;
	lng: number;
};

export type ChatFavoriteMarkersFolder = {
	id: number;
	name: string;
	number_of_markers: number;
	layerId: string;
	markers: ChatFavoriteMarker[];
	text?: string;
};

export type ChatCustomMarkersFolder = {
	id: number;
	name: string;
	number_of_markers: number;
	markers: ChatCustomMarker[];
	text?: string;
};
