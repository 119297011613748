import {FeatureCollectionGeoJson, FeatureGeoJson} from 'src/app/helpers/shareHelper';
import {MEASUREMENT_SYSTEMS} from '../constants';
import {
	COLORS,
	COMPANY_PERMISSIONS,
	ContentType,
	DrawTools,
	FEATURES,
	HERE_SHIPPED_HAZARDOUS_GOODS,
	HERE_TRANSPORT_MODE,
	HERE_TUNNEL_CATEGORY,
	HERE_VEHICLE_CATEGORY,
	HERE_VEHICLE_TYPE,
	HERE_WEIGHT_PER_AXLE_GROUP,
	ItemType,
	LANGUAGES,
	MarkerType,
	POPUPS_TO_SHOW,
	SearchResultType,
	SHARE_TYPES,
	SIDEBAR_TO_SHOW,
	StopType,
	USER_PERMISSIONS,
} from '../enums';
import {LatLngLiteral, MarkerLabel} from '@agm/core';

// export type UserLogged = Partial<UserDto> & {layerPreference?: string};
export type SearchResult = Partial<UserDto> & Partial<EnterprisePlanDto> & {resultType: SearchResultType};

export type RecentSearchDto = MarkerDto & {layerId: string};

export type Point = 'CLUSTER' | 'MARKER';

export interface ShowCenterPinOnMap {
	show: boolean;
	color: COLORS;
}

export interface GeoLocationDto {
	lat: number;
	long: number;
}

export interface TranslateDto {
	text: string;
	lang?: LANGUAGES;
}

export interface Token {
	token: string;
}
export interface CompanyDto {
	id: string;
	name: string;
	//businessSubscriptions: BusinessSubscriptionDto
}

export interface CouponDto {
	id?: number;
	code?: string;
	couponType?: string;
	credits?: number;
	userType?: string;
	createdAt?: Date | string;
	updatedAt?: Date | string;
}

export interface MultiStopDto {
	type: string;
	bearing?: number;
	stops: GeoLocationDto[];
}

export interface HazmatRouteRequest {
	origin: LatLngLiteral;
	destination: LatLngLiteral;
	profileId: number;
	profile?: VehicleProfileDto;
	via: LatLngLiteral[];
	departureTime?: Date;
	arrivalTime?: Date;
	transportMode: HERE_TRANSPORT_MODE;
	lang: string[];
}

export interface MultiStopRouteDto {
	id?: number;
	name: string;
	description?: string;
	layerId: string;
	stops: StopDto[];
	draws: MapDrawingDto[];
	drawIds?: number[];
	startingCustomRouteIds?: number[];
	markers?: GeoNoteDto[];
	osrmRoute?: any;
	isHazmat?: boolean;
	vehicleProfileId?: number;
	vehicleProfile?: VehicleProfileDto;
	createdByUser?: UserBasicInfoDto;
	url?: string;
	items?: GeoNoteDto[];
	routeReturn?: number;
	hasRouteReturn?: boolean;
	isRouteReturn?: boolean;
	return?: MultiStopRouteDto;
}

export interface StopDto {
	id?: number;
	type: StopType;
	name: string;
	lat?: number;
	lng?: number;
}

export interface ClusterDto {
	lng: number;
	lat: number;
	geohash: string;
	count: string;
	layerId: string;
	stateId: string;
	zoom: number;
	point?: Point;
	label?: MarkerLabel;
}

export interface EnterprisePlanDto {
	id?: string;
	name: string;
	description: string;
	expirationDate: Date | string;
	company: string | CompanyDto;
	boughtDate: Date | string;
	numberOfLicenses: number;
	enabled?: boolean;
	layers?: Partial<LayerDto>[];
	features?: Partial<FeatureDto>[];
	users?: Partial<UserDto>[];
	usersOnQueue?: Partial<UserDto>[];
	createdAt: Date;
	updatedAt: Date;
	numberOfUsers?: number;
	staff?: boolean;
}

export interface FavoriteDto {
	id?: number;
	markerId?: number;
	nickname?: string;
	color?: string;
	folders?: number[];
	gates?: GateDto[];
	rootFolder?: boolean;
	lat: number;
	lng: number;
	layerId?: string;
	apiNum?: string;
	createdByUser?: UserBasicInfoDto;
}

export interface UserBasicInfoDto {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
}

export interface FeatureDto {
	id?: string;
	name: string;
	description: string;
	enabled: boolean;
	isFree: boolean;
}

export interface FolderDto {
	id: number;
	name: string;
	layerId?: string;
	numberOfMarkers?: number;
	favoriteMarkers?: FavoriteDto[];
	createdByUser?: UserBasicInfoDto;
}

export interface GateDto {
	id: number;
	name: string;
	lng: number;
	lat: number;
	userId: number;
	markerId: number;
	iconUrl?: string;
	longitude?: number;
	latitude?: number;
	selected?: boolean;
	newGate?: boolean;
	createdByUser?: UserBasicInfoDto;
}

export interface LayerDto {
	id?: string;
	name: string;
	description: string;
	enabled?: boolean;
	icon: string;
	isFree: boolean;
	displayName: string;
	type: string;
	countryId: string;
}

export interface MarkerDto {
	id?: number;
	lng: number;
	lat: number;
	name?: string;
	apiNum?: string;
	status?: string;
	layerId?: string;
	wellType?: string;
	county?: string;
	version?: number;
	geoHash?: string;
	createdAt?: Date;
	updatedAt?: Date;
	gates?: GateDto[];
	favorite?: FavoriteDto;
	color?: string;
	iscustomMarker?: boolean;
	distance?: number;
	unit?: 'Mi' | 'Ft';
	recentSearchId?: number;
	isNewMarker?: boolean;
	createdByUser?: UserBasicInfoDto;
}

export interface NotificationDto {
	id: number;
	read: boolean;
	type: string;
	businessSubscriptionId: string;
	createdAt: Date;
}
export interface CustomMarkerFolderDto {
	id: number;
	name: string;
	numberOfMarkers: number;
	customMarkers: CustomMarkerDto[];
	createdByUser?: UserBasicInfoDto;
}

export interface CustomMarkerDto {
	id?: number;
	lng: number;
	lat: number;
	name?: string;
	color?: string;
	iscustomMarker?: boolean;
	isNewMarker?: boolean;
	latitude?: number;
	longitude?: number;
	rootFolder?: boolean;
	folders?: OrderDto[];
	createdByUser?: UserBasicInfoDto;
}

export interface OrderDto {
	folderId: number;
	order: number;
}

export interface MapMarker {
	id?: number;
	lng: number;
	lat: number;
	iconUrl?: string;
	color?: string;
	iscustomMarker?: boolean;
	isNewMarker?: boolean;
	name?: string;
	zIndex?: number;
}

export interface GeoNoteDto extends NoteDto {
	id: string;
	lat?: number;
	lng?: number;
	color?: string;
	stopType?: string;
	marker?: google.maps.Marker;
	radius?: number;
	infoWindow?: google.maps.InfoWindow;
}
export interface NoteDto {
	title?: string;
	description: string;
	image?: string;
	content?: ItemContent[];
	type?: ItemType;
}

export interface PolylineData {
	id: string;
	polyline?: google.maps.Polyline;
}

export interface MapDrawCustomRouteData {
	id?: number;
	name?: string;
	description?: string;
	distance?: number;
	toolSelected?: string | null;
	color?: string;
	colorCode?: string;
	paths?: PolylineData[]; // array of Polylines
	notes?: GeoNoteDto[]; // array of notes
	markers?: GeoNoteDto[];
	stops?: GeoNoteDto[];
	startPoint?: GeoNoteDto;
	endPoint?: GeoNoteDto;
	active?: boolean;
	userId?: number;
	startingCustomRoute?: boolean;
}

export interface MapDrawingDto {
	id?: number;
	userId: number;
	name: string;
	description: string;
	distance: number;
	color: string;
	polylines: Partial<PolylineDto>[];
	items: Partial<ItemData>[];
	url?: string;
	startingCustomRoute?: boolean;
}

export interface MapRecordedRouteDto {
	id?: number;
	name: string;
	description?: string;
	isHazmat?: boolean;
	polylines: PolylineDto[];
	items: Partial<ItemData>[];
	createdByUser: UserBasicInfoDto;
	url?: string;
	reviewedBy?: number[];
	createdAt?: Date;
}

export interface MapRecordedRouteDtoToShare {
	id?: number;
	routeId?: number;
	name: string;
	isHazmat: boolean;
	description?: string;
	geoJson: FeatureCollectionGeoJson;
	items: Partial<ItemData>[];
	createdByUser: UserBasicInfoDto;
}

export interface MapRecordedRouteOutputDto {
	id?: string;
	name: string;
	description?: string;
	geoJson: FeatureCollectionGeoJson;
	items: Partial<ItemData>[];
}

export interface PolylineDto {
	coordinates: LatLngLiteral[];
}

export interface ItemData {
	title: string;
	radius: number;
	description: string;
	lat: number;
	lng: number;
	translated_title?: string;
	translated_description?: string;
	stopType?: string;
	color?: string;
	type: ItemType;
	content?: ItemContent[];
	image?: string;
}

export interface ItemContent {
	id?: string;
	type: string;
	data: string;
	transcription?: string;
}
export interface PlanDto {
	id: string;
	name: string;
	display: boolean;
	entitlementId: string;
	description: string;
	enables: boolean;
	features: any[];
	layers: any[];
}

export interface InviteAllowedDto {
	restricted: boolean;
	exists: boolean;
	alreadyInPlan: boolean;
	userId?: string;
}

export interface InviteUserToPlanDto {
	businessPlanId: string;
	invitedUserId: string;
}

export interface RoleDto {
	id: string;
	function: string;
}

export interface UserTracking {
	userId: number;
	time: String;
	lat: number;
	lng: number;
	firstName: string;
	lastName: string;
}

export interface ExternalUserTracking {
	time: Date;
	deviceId: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	// web app use coordinates in numeric
	lng: number;
	lat: number;
}

export interface ShareReceiver {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
}

export interface ExternalShareReceiver {
	deviceId: string;
	firstName: string;
	lastName: string;
	email: string;
}

export interface ShareRouteDto {
	type: SHARE_TYPES;
	data: MapRecordedRouteDto | MapDrawingDto | MultiStopRouteDto;
}

export interface ShareAssetDto {
	userId?: number;
	favorites?: number[];
	folders?: number[];
	customFolders?: number[];
	rootFolder?: boolean;
	routes?: number[];
	customRoutes?: MultiStopRouteDto[];
	drawing?: number;
	customDrawing?: MapDrawingDto;
	recordRoutes?: number[];
	markers?: number[];
	pins?: number[];
	users?: number[];
	people?: string[];
	message?: string;
	shareBy?: string;
}

export interface ShareExternalAssetDto {
	userId?: number;
	routes?: number[];
	customRoutes?: MultiStopRouteDto[];
	drawing?: number;
	customDrawing?: MapDrawingDto;
	recordRoutes?: number[];
	users?: number[];
	people?: string[];
	message?: string;
	shareBy?: string;
	links?: string[];
	emailLink?: {message: string; emails: string[]; userNameSender: string; link: string};
	licenseId: number;
}

export interface InvitedUserDto {
	id: string;
	creatorUser: UserDto;
	invitedUser: UserDto;
	businessSubscription: EnterprisePlanDto;
	businessSubscriptionId: string;
	companyId: string;
	status: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface CountryState {
	id: string;
	countryId: string;
}

export interface RegularLoginResponse extends UserLogged {
	session?: string;
}
export interface UserLogged {
	id: number;
	staff: boolean;
	email: string;
	firstName: string;
	lastName: string;
	createdAt: Date;
	updatedAt: Date;
	layers: LayerDto[];
	features: FeatureDto[];
	states: CountryState[];
	layerPreference: string;
	webSessionToken: string;
}

export interface UserDto {
	id?: number;
	email: string;
	password?: string;
	firstName: string;
	lastName: string;
	userType?: string;
	roles: RoleDto[];
	phoneNumber?: string;
	otherRole?: string;
	employer?: string;
	platform?: string;
	states?: string[];
	threadId?: string;
	subscriptionsPlans?: PlanDto[];
	businessSubscriptions?: EnterprisePlanDto[];
	businessSubscriptionId?: string;
	country?: string;
	features?: FeatureDto[];
	source?: string;
	language?: string;
	appVersion?: string;
	createdAt?: Date | string;
	updatedAt?: Date | string;
	layers?: LayerDto[];
}

export interface PermissionDto {
	featureId: USER_PERMISSIONS | FEATURES | COMPANY_PERMISSIONS;
	allowed: boolean;
}

export interface PopupStorage {
	id: POPUPS_TO_SHOW;
	shownAt: Date;
}

export interface ShowSidebar {
	id: SIDEBAR_TO_SHOW;
	metadata?: ShowSidebarMetadata;
}

export type ShowSidebarMetadata = ShowSidebarAddPinMetadata | undefined;
export interface ShowSidebarAddPinMetadata {
	type: MarkerType;
}
export interface ImageDto {
	image: string | ArrayBuffer;
	type: string;
}
export interface ImageResponseDto {
	id: string;
}

export interface PortalFeaturesEnabled {
	SEARCH_LAYER: boolean;
	FAVORITES: boolean;
	PINS: boolean;
	USERS_LOCATION: boolean;
	ROUTES: boolean;
	CUSTOM_ROUTES: boolean;
	CHAT: boolean;
	USERS: boolean;
	SETTINGS: boolean;
	VEHICLE_PROFILES: boolean;
	SHARE_BETWEEN_PORTAL_USERS: boolean;
	DRAG_AND_DROP_AUTOMATED_ROUTE_EDIT: boolean;
	INSIGHTS_DASHBOARD: boolean;
}

export interface MapOptionsToShow {
	LOCK_ZOOM: boolean;
	ZOOM_IN: boolean;
	ZOOM_OUT: boolean;
	SET_CURRENT_LOCATION: boolean;
	MAP_SETTINGS: boolean;
	FAVORITES: boolean;
	LAYERS: boolean;
	ADD_NOTE: boolean;
	FIT_BOUNDS: boolean;
}

export interface VehicleProfileDto extends Omit<HEREVehicle, 'shippedHazardousGoods'> {
	id?: number;
	profileName: string;
	profileDescription: string;
	default?: boolean;
	units: MEASUREMENT_SYSTEMS;

	transportMode: HERE_TRANSPORT_MODE;

	createdByUserId?: number;
	createdByUser?: UserDto;

	users?: UserDto[];

	shippedHazardousGoods: HERE_SHIPPED_HAZARDOUS_GOODS[];
}

export interface HEREVehicle {
	shippedHazardousGoods?: string; // Comma-separated list of shipped hazardous goods in the vehicle
	grossWeight?: number; // Vehicle weight including trailers and shipped goods, in kilograms.
	weightPerAxle?: number; // Vehicle weight per axle, in kilograms.
	weightPerAxleGroup?: HERE_WEIGHT_PER_AXLE_GROUP; // Weight of different axle groups like single and tandem axles, in kilograms.
	height?: number | string; // Vehicle height, in centimeters.
	width?: number | string; // Vehicle width, in centimeters.
	length?: number | string; // Vehicle length, in centimeters.
	tunnelCategory?: HERE_TUNNEL_CATEGORY; // Specifies the tunnel category used to restrict transport of specific goods.
	axleCount?: number; // Defines total number of axles in the vehicle.
	trailerAxleCount?: number; // Specifies the total number of axles across all the trailers attached to the vehicle.
	tiresCount?: number; // Value specifying the total number of tires the vehicle has i.e., tires on the base vehicle and any attached trailers.
	category?: HERE_VEHICLE_CATEGORY; // Specifies the category of the vehicle.
	trailerCount?: number; // Number of trailers attached to the vehicle.
	hovOccupancy?: number; // Number of occupants (defined as occupying a seat with a restraint device) in the vehicle.
	licensePlate?: string; // Specifies information about the vehicle's license plate number.
	speedCap?: number; // Specifies the maximum speed, in m/s, which the user wishes not to exceed.
	type?: HERE_VEHICLE_TYPE; // Specifies the type of vehicle.
}

export interface StateDto {
	id: string;
	name: string;
	countryId: string;
	countryName: string;
	selected: boolean;
}

export interface RecipientLicenseDto {
	id: number;
	name: string;
	expirationDate: string;
	createdAt: string;
	numberOfLicenses: number;
	numberOfUsers: number;
	numberOfUsedLicenses: number;
	numberOfExceededRequests: number;
	users: [];
	key: string;
	firstActiveDate?: Date;
	lastActiveDate?: Date;
}

export interface ExternalUserDto {
	deviceId: string;
	firstName: string;
	lastName: string;
	email: string;
	company: string;
	phoneNumber: string;
	licenses: RecipientLicenseDto[];
}
