import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {
	MapDrawingDto,
	MapRecordedRouteDto,
	MapRecordedRouteDtoToShare,
	MultiStopRouteDto,
	RecipientLicenseDto,
	ShareAssetDto,
	ShareExternalAssetDto,
	ShareRouteDto,
	UserDto,
	UserLogged,
} from 'src/app/types/dtos/models';
import {ToastService} from 'src/app/services/toast.service';
import {UserService} from 'src/app/services/user.service';
import {Subscription, throwError} from 'rxjs';
import * as _ from 'lodash';
import {RecipientLicenseService} from 'src/app/services/recipientLicense.service';
import {LoaderService} from 'src/app/services/loader.service';
import {DrawService} from 'src/app/services/draw.service';
import {v4 as uuid} from 'uuid';
import {DrawingToShareDto, polylinesToGeoJson} from 'src/app/helpers/shareHelper';
import {ChatRoute, MESSAGE_TYPES, Message} from 'src/app/types/dtos/chat';
import {ChatService} from 'src/app/services/chat.service';
import {AuthService} from 'src/app/services/auth.service';
import {ShareService} from 'src/app/services/share.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {FEATURES, SHARE_TYPES, StopType} from 'src/app/types/enums';
import {take} from 'rxjs/operators';
import {RouteService} from 'src/app/services/route.service';

enum PanelTabOptions {
	INTERNAL_USERS = 'INTERNAL_USERS',
	EXTERNAL_LINKS = 'EXTERNAL_LINKS',
	EXTERNAL_LINKS_COPY_LINK = 'EXTERNAL_LINKS_COPY_LINK',
	EXTERNAL_LINKS_SHARE_EMAIL = 'EXTERNAL_LINKS_SHARE_EMAIL',
}

@Component({
	selector: 'app-external-share',
	templateUrl: './external-share.component.html',
})
export class ExternalShareComponent implements OnInit, OnDestroy {
	@Input() show: boolean;
	@Output() cancel = new EventEmitter();
	@Input() asset: ShareRouteDto;
	public selectedPanel: PanelTabOptions = PanelTabOptions.INTERNAL_USERS;
	users: UserDto[] = [];
	searchValue: string;
	filteredUsers: Partial<UserDto>[];
	selectedUsers: Partial<UserDto>[] = [];
	recipientLicenses: RecipientLicenseDto[] = [];
	recipientLicenseSelected: RecipientLicenseDto = this.recipientLicenses ? this.recipientLicenses[0] : null;
	linkDisplay: boolean = false;
	links: {urlToSite: string; urlReturn: string};
	private currentUser: UserLogged;
	private userSubscription: Subscription;
	public externalShareFeature = false;
	public peopleToSend: string[] = [];
	public messageToSend = '';

	constructor(
		private toastService: ToastService,
		private userService: UserService,
		private recipientLicenseService: RecipientLicenseService,
		private loaderService: LoaderService,
		private drawService: DrawService,
		private chatService: ChatService,
		private authService: AuthService,
		private routeService: RouteService,
		private shareService: ShareService,
		private clipboard: Clipboard
	) {}
	ngOnInit(): void {
		this.getUsers();
		this.userSubscription = this.authService.currentUser.subscribe((data) => (this.currentUser = data));
		this.externalShareFeature = this.authService.userHasFeature(FEATURES.CREATE_EXTERNAL_SHARE_LINKS);
		if (this.externalShareFeature) {
			this.getLicenses();
		}
	}

	ngOnDestroy(): void {
		this.userSubscription.unsubscribe();
	}

	get valueFilter(): string {
		return this.searchValue;
	}

	set valueFilter(value: string) {
		this.searchValue = value;
		this.filteredUsers = this.filterUsers(this.searchValue);
	}

	public handleSelectedLicense = (licenseId: number) => {
		this.recipientLicenseSelected = this.recipientLicenses.find((license) => licenseId === license.id);
	};

	public selectPanel = (newPanel: string) => {
		switch (newPanel) {
			case PanelTabOptions.INTERNAL_USERS:
				this.selectedPanel = PanelTabOptions.INTERNAL_USERS;
				break;
			case PanelTabOptions.EXTERNAL_LINKS:
				this.selectedPanel = PanelTabOptions.EXTERNAL_LINKS;
				break;
			case PanelTabOptions.EXTERNAL_LINKS_COPY_LINK:
				this.selectedPanel = PanelTabOptions.EXTERNAL_LINKS_COPY_LINK;
				break;
			case PanelTabOptions.EXTERNAL_LINKS_SHARE_EMAIL:
				this.selectedPanel = PanelTabOptions.EXTERNAL_LINKS_SHARE_EMAIL;
				break;
		}
	};

	getUsers = async () => {
		try {
			this.loaderService.show();
			const result = await this.userService.getAllUsers();
			this.users = result.data;
			this.filteredUsers = this.filterUsers('');
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'Could not get users', detail: 'Try again later'});
		} finally {
			this.loaderService.hide();
		}
	};

	filterUsers(filterBy: string): UserDto[] {
		filterBy = filterBy.toLocaleLowerCase();
		return this.users.filter(
			(user: UserDto) =>
				`${user.firstName.toLocaleLowerCase()} ${user.lastName.toLocaleLowerCase()}`.indexOf(filterBy) !== -1 ||
				user.email.toLocaleLowerCase().indexOf(filterBy) !== -1
		);
	}

	public removeModal = () => {
		this.show = false;
		this.cancel.emit();
		this.selectedUsers = [];
		this.selectedPanel = PanelTabOptions.INTERNAL_USERS;
		this.linkDisplay = false;
		this.links = null;
		this.peopleToSend = [];
		this.messageToSend = '';
	};

	public handleSendEmail = async () => {
		if (!this.messageToSend.length || !this.peopleToSend.length) {
			this.toastService.show({
				severity: 'error',
				summary: 'Message not sent, please check the inputs and try again',
			});
			return;
		}
		try {
			const licenseId = this.recipientLicenseSelected.id;
			const userId = this.currentUser.id;
			const people = this.peopleToSend;
			const message = this.messageToSend;
			let shareAssetDto: ShareExternalAssetDto = {
				userId,
				licenseId,
				message,
				people,
				links: [this.links.urlToSite],
			};
			if (this.links.urlReturn) shareAssetDto.links.push(this.links.urlReturn);
			if (this.asset.type === SHARE_TYPES.ROUTE) {
				shareAssetDto.routes = [this.asset.data.id];
			}
			if (this.asset.type === SHARE_TYPES.CUSTOM_ROUTE) {
				shareAssetDto.drawing = this.asset.data.id;
			}
			if (this.asset.type === SHARE_TYPES.RECORDED_ROUTE) {
				shareAssetDto.recordRoutes = [this.asset.data.id];
			}
			await this.shareService.shareExternalAsset(shareAssetDto);
			this.toastService.show({
				severity: 'success',
				summary: 'Message sent successfully',
			});
			this.removeModal();
		} catch (error) {
			this.toastService.show({
				severity: 'error',
				summary: 'Message not sent, please check the inputs and try again',
			});
		}
	};

	public cancelLink = () => {
		this.linkDisplay = false;
		this.links = null;
		this.selectedPanel = PanelTabOptions.EXTERNAL_LINKS;
	};

	private getLicenses = async () => {
		const data = await this.recipientLicenseService.getAllLicensesFromCompany();
		this.recipientLicenses = data.data;
		this.recipientLicenseSelected = this.recipientLicenses[0];
	};

	public handleCreateLink = async () => {
		try {
			this.loaderService.show();
			const shareExternalAssetDto: ShareExternalAssetDto = {licenseId: this.recipientLicenseSelected.id};
			if (this.asset.type === 'CUSTOM_ROUTE') {
				const custom_route = this.asset.data;
				shareExternalAssetDto.drawing = custom_route.id;
				shareExternalAssetDto.userId = this.currentUser.id;
			}
			if (this.asset.type === 'ROUTE') {
				const route = this.asset.data as MultiStopRouteDto;
				shareExternalAssetDto.routes = [route.id];
				shareExternalAssetDto.userId = route.createdByUser?.id ?? this.currentUser.id;
			}
			if (this.asset.type === 'RECORDED_ROUTE') {
				const recorded_route = this.asset.data as MapRecordedRouteDto;
				shareExternalAssetDto.recordRoutes = [recorded_route.id];
				shareExternalAssetDto.userId = recorded_route.createdByUser?.id ?? this.currentUser.id;
			}
			shareExternalAssetDto.users = this.selectedUsers.map((user) => +user.id);
			const response = await this.shareService.shareExternalAsset(shareExternalAssetDto);
			this.links = response.data.links;
			this.linkDisplay = true;
			this.selectPanel(PanelTabOptions.EXTERNAL_LINKS_COPY_LINK);
		} catch (error) {
			throwError(error);
			this.toastService.show({severity: 'error', summary: error.error?.message});
		} finally {
			this.loaderService.hide();
		}
	};

	public shareAsset = async (via: string) => {
		try {
			this.loaderService.show();
			if (via === 'CHAT') {
				if (this.asset.type === 'ROUTE') {
					const route = this.asset.data as MultiStopRouteDto;
					route.stops = route.stops.filter((stop) => stop.type !== StopType.USER);
					const draws = await this.parseDraws(route);
					this.selectedUsers.forEach(async (user: UserDto) => {
						const message: Partial<Message<ChatRoute>> = this.createRouteMessage(route, draws);
						const content = message.content;
						Object.keys(content).forEach((key) => content[key] === undefined && delete content[key]);
						await this.chatService.sendMessage(message, user.threadId);
					});
					if (route.return) {
						const getFullReturnRoute = await this.routeService.getFullRoute(route.return.id);
						const returnRoute = getFullReturnRoute.data;
						Object.assign(returnRoute, {drawIds: returnRoute.draws.map((d) => d.id)});
						returnRoute.stops = returnRoute.stops.filter((stop) => stop.type !== StopType.USER);
						const drawsReturn = await this.parseDraws(returnRoute);
						this.selectedUsers.forEach(async (user: UserDto) => {
							const message: Partial<Message<ChatRoute>> = this.createRouteMessage(
								returnRoute,
								drawsReturn
							);
							const content = message.content;
							Object.keys(content).forEach((key) => content[key] === undefined && delete content[key]);
							await this.chatService.sendMessage(message, user.threadId);
						});
					}
					this.toastService.show({
						severity: 'success',
						summary: `Shared route '${route.name}' successfully.`,
					});
					this.removeModal();
				} else if (this.asset.type === 'CUSTOM_ROUTE') {
					const custom_route = this.asset.data as MapDrawingDto;
					const polylines = polylinesToGeoJson(custom_route.polylines);
					const DRAW_ROUTE_NEW_ID_OFFSET = 200_000;
					const content: DrawingToShareDto = {
						id: custom_route.id || uuid(),
						routeId:
							custom_route.id && !isNaN(+custom_route.id)
								? +custom_route.id + DRAW_ROUTE_NEW_ID_OFFSET
								: undefined,
						name: custom_route.name,
						description: custom_route.description,
						distance: custom_route.distance,
						color: custom_route.color,
						items: custom_route.items,
						polylines,
						userId: custom_route.userId,
					};
					this.selectedUsers.forEach(async (user: UserDto) => {
						const message: Partial<Message<string>> = {
							message_type: MESSAGE_TYPES.CUSTOM_ROUTE,
							content: JSON.stringify(content),
						};
						await this.chatService.sendMessage(message, user.threadId);
					});
					this.toastService.show({
						severity: 'success',
						summary: `Shared custom route ${custom_route.name} successfully.`,
					});
					this.removeModal();
				} else if (this.asset.type === 'RECORDED_ROUTE') {
					const recorded_route = this.asset.data as MapRecordedRouteDto;
					const RECORDED_ROUTE_NEW_ID_OFFSET = 300_000;
					const content: MapRecordedRouteDtoToShare = {
						id: recorded_route.id || uuid(),
						routeId: recorded_route.id + RECORDED_ROUTE_NEW_ID_OFFSET,
						name: recorded_route.name,
						description: recorded_route.description,
						items: recorded_route.items,
						isHazmat: !!recorded_route.isHazmat,
						geoJson: polylinesToGeoJson(recorded_route.polylines),
						createdByUser: recorded_route.createdByUser,
					};
					this.selectedUsers.forEach(async (user: UserDto) => {
						const message: Partial<Message<string>> = {
							message_type: MESSAGE_TYPES.RECORDED_ROUTE,
							content: JSON.stringify(content),
						};
						await this.chatService.sendMessage(message, user.threadId);
					});
					this.toastService.show({
						severity: 'success',
						summary: `Shared ${recorded_route.name} successfully.`,
					});
					this.removeModal();
				}
			} else if (['EMAIL', 'SMS'].includes(via)) {
				let shareAssetDto: ShareAssetDto = {};
				if (this.asset.type === 'CUSTOM_ROUTE') {
					const custom_route = this.asset.data as MapDrawingDto;
					shareAssetDto.drawing = custom_route.id;
					shareAssetDto.userId = this.currentUser.id;
				}
				if (this.asset.type === 'ROUTE') {
					const route = this.asset.data as MultiStopRouteDto;
					shareAssetDto.routes = [route.id];
					shareAssetDto.userId = route.createdByUser?.id ?? this.currentUser.id;
				}
				if (this.asset.type === 'RECORDED_ROUTE') {
					const recorded_route = this.asset.data as MapRecordedRouteDto;
					shareAssetDto.recordRoutes = [recorded_route.id];
					shareAssetDto.userId = recorded_route.createdByUser?.id ?? this.currentUser.id;
				}
				shareAssetDto = {
					...shareAssetDto,
					users: this.selectedUsers.map((user) => +user.id),
					shareBy: via,
				};
				await this.shareService.shareAssets(shareAssetDto);
				this.toastService.show({
					severity: 'success',
					summary: `${via} successfully sent!`,
				});
				this.removeModal();
			} else {
				this.removeModal();
				this.toastService.show({severity: 'error', summary: 'Invalid via message'});
			}
		} catch (error) {
			throwError(error);
			console.error(error);
			if (error.error?.error === 'INVALID_PHONE_NUMBERS') {
				const erros: string[] = JSON.parse(error.error.message).errorPhones;
				erros.forEach((user) =>
					this.toastService.show({
						severity: 'error',
						summary: `${user} have no number or is invalid`,
					})
				);
			} else if (error.error?.error === 'INVALID_PAYLOAD') {
				this.toastService.show({
					severity: 'error',
					summary: `Invalid payload`,
				});
			} else {
				this.toastService.show({
					severity: 'error',
					summary: 'Sharing was not possible',
					detail: 'Try again later',
				});
			}
		} finally {
			this.removeModal();
			this.loaderService.hide();
		}
	};

	private createRouteMessage = (route, draws): Partial<Message<ChatRoute>> => {
		const AUTOMATED_ROUTE_NEW_ID_OFFSET = 100_000;
		return {
			message_type: MESSAGE_TYPES.ROUTE,
			content: {
				id: route.id || null,
				name: route.name || null,
				description: route.description || null,
				layerId: route.layerId || null,
				draws,
				stops: route.stops.map((stop) => ({
					id: stop.id || null,
					type: stop.type,
					name: stop.name,
					lat: stop.lat || null,
					lng: stop.lng || null,
				})),
				isHazmat: route.isHazmat,
				items: route.items,
				vehicleProfileId: route.vehicleProfileId || null,
				vehicleProfile: route.vehicleProfile || null,
				routeId: route.id ? route.id + AUTOMATED_ROUTE_NEW_ID_OFFSET : null,
			},
		};
	};

	private parseDraws = async (route) => {
		let drawsToSend = [];
		for (const id of route.drawIds || []) {
			const res = await this.drawService.getFullDraw(id);
			const draw = res.data;
			const polylines = polylinesToGeoJson(draw.polylines);
			const content: DrawingToShareDto = {
				id: draw.id || uuid(),
				name: draw.name,
				description: draw.description,
				distance: draw.distance,
				color: draw.color,
				items: draw.items,
				polylines,
				userId: draw.userId,
			};
			drawsToSend.push(content);
		}

		if (route.startingCustomRouteIds) {
			for (const customRouteId of route.startingCustomRouteIds) {
				const res = await this.drawService.getFullDraw(customRouteId);
				const draw = res.data;
				const polylines = polylinesToGeoJson(draw.polylines);
				const content: DrawingToShareDto = {
					id: draw.id || uuid(),
					name: draw.name,
					description: draw.description,
					distance: draw.distance,
					color: draw.color,
					items: draw.items,
					polylines,
					userId: draw.userId,
					startingCustomRoute: true,
				};
				drawsToSend.push(content);
			}
		}
		delete route.startingCustomRouteIds;
		return JSON.stringify(drawsToSend);
	};

	public handleCopyLink = () => {
		this.clipboard.copy(this.links.urlToSite);
		this.toastService.show({severity: 'success', summary: 'Copied to clipboard'});
	};

	public handleShareByEmail = () => {
		this.linkDisplay = false;
		this.selectedPanel = PanelTabOptions.EXTERNAL_LINKS_SHARE_EMAIL;
	};
}
